import classNames from "classnames";
import { Layout } from "organisms";
import { Headline, Image, Metadata, Obl } from "atoms";
import YouTube from "react-youtube";
import React from "react";
import Chart1Svg from "images/company/chart1.svg";
import Chart2Svg from "images/company/chart2.svg";
import Chart3Svg from "images/company/chart3.svg";
import Icon1Svg from "images/company/icon1.svg";
import Icon2Svg from "images/company/icon2.svg";
import Icon3Svg from "images/company/icon3.svg";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import * as styles from "./index.module.scss";

const CompanyPage = () => (<div data-testid="company" className={styles.company}>
    <Metadata
        title="Our Company"
        description="Here are details about Melalogic, the company."
    />
    <article>
        <Layout>
            <div className={classNames("row center-sm middle-sm", styles.topRow)}>
                <div className="col-xs-12 col-sm-5 start-sm">
                    <Headline>Our Company</Headline>
                    <p>Melalogic is a platform that gives black people a single source of skin health information from trusted professionals who look like them.</p>
                </div>
                <div className="col-xs-12 col-sm-5">
                    <div className={styles.video}>
                        <YouTube videoId="QG96itqmjo8" />
                    </div>
                </div>
            </div>
            <div className="row center-sm">
                <div className="col-xs-12 col-sm-10 start-sm">
                    <Headline>Problem</Headline>
                    <p>Black people die or are injured from completely treatable skin conditions.</p>
                </div>
            </div>
            <div className="row center-sm">
                <div className="col-xs-12 col-sm-3 start-sm">
                    <div className={styles.chart}>
                        <img src={Chart1Svg} alt="chart 1" />
                        <Headline level={4}>
                            <span className={styles.number}>25%</span>
                            <span className={styles.text}>more likely to die<br /> if black</span>
                        </Headline>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-3 col-sm-offset-1 start-sm">
                    <div className={styles.chart}>
                        <img src={Chart2Svg} alt="chart 2" />
                        <Headline level={4}>
                            <span className={styles.number}>~100%</span>
                            <span className={styles.text}>black population<br /> at-risk</span>
                        </Headline>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-3 col-sm-offset-1 start-sm">
                    <div className={styles.chart}>
                        <img src={Chart3Svg} alt="chart 3" />
                        <Headline level={4}>
                            <span className={styles.number}>97%</span>
                            <span className={styles.text}>of all dermatologists<br /> need training</span>
                        </Headline>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-10 start-sm">
                    <p>Linda Carroll, "Many dermatologists need more training on African American skin and hair," Reuters, August 21 2019, <Obl to="https://www.reuters.com/article/us-health-dermatology-black-perceptions/many-dermatologists-need-more-training-on-african-american-skin-and-hair-idUSKCN1VB2IG" title="Reuters article">https://www.reuters.com/article/us-health-dermatology-black-perceptions/many-dermatologists-need-more-training-on-african-american-skin-and-hair-idUSKCN1VB2IG</Obl></p>
                </div>
            </div>
            <div className="row center-sm middle-sm">
                <div className="col-xs-12 col-sm-5 start-sm">
                    <Headline level={2}>The Gap</Headline>
                    <p>There is no convenient way for a black person to access resources that display what a skin issue could potentially look like on them...until now.</p>
                </div>
                <div className="col-xs-12 col-sm-5">
                    <Image filename="thegap-min.jpg" alt="visual aid of white male in doctor's office" />
                </div>
            </div>
            <div className="row center-sm">
                <div className="col-xs-12 col-sm-10 start-sm">
                    <Headline>Solution</Headline>
                    <p>Melalogic is a platform that aims to protect black people from being killed or injured from treatable skin conditions.</p>
                    <p>In short, Melalogic modernizes the skin health experience for black people.</p>
                </div>
            </div>
            <div className="row center-xs">
                <div className="col-xs-12 col-sm-10">
                    <Headline>Visual Aids</Headline>
                </div>
                <div className="col-xs-12 col-sm-4">
                    <div className={styles.visualAid}>
                        <Image filename="visualaid-example-traction-alopecia-min.jpg" alt="Example of Traction Alopecia Visual Aid" />
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4">
                    <div className={styles.visualAid}>
                        <Image filename="visualaid-example-melanoma-min.jpg" alt="Example of Melanoma Visual Aid" />
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4">
                    <div className={styles.visualAid}>
                        <Image filename="visualaid-example-central-centrifigual-cicatricial-alopecia-min.jpg" alt="Example of Central Centrifugal Cicatricial Visual Aid" />
                    </div>
                </div>
            </div>
            <div className="row center-xs">
                <div className="col-xs-12 col-sm-10">
                    <Headline>App</Headline>
                    <p>Users will be able to <strong>submit a photo of any skin issue they may have</strong> and receive instaneous feedback as to what it could be, and suggestions on how they should treat it.</p>
                </div>
                <div className={styles.app}>
                    <div className="row center-xs middle-sm">
                        <div className="col-xs-12 col-sm-5">
                            <div className={styles.phone}>
                                <Image filename="phone-min.png" alt="mobile phone" />
                                <br />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <Image filename="laptop-min.png" alt="laptop" />
                        </div>
                    </div>
                </div>
                <div className={styles.features}>
                    <div className="row center-xs">
                        <div className="col-xs-12 col-sm-3">
                            <img src={Icon1Svg} alt="icon" />
                            <p>Melalogic is a Self-Health Management App for Individuals and Families</p>
                        </div>
                        <div className="col-xs-12 col-sm-3 col-sm-offset-1">
                            <img src={Icon2Svg} alt="icon" />
                            <p>Melalogic provides Dermatology Practitioner Resources</p>
                        </div>
                        <div className="col-xs-12 col-sm-3 col-sm-offset-1">
                            <img src={Icon3Svg} alt="icon" />
                            <p>Melalogic has a Products &amp; Services Marketplace</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row center-sm">
                <div className="col-xs-12 col-sm-10 start-sm">
                    <div id="milestones" />
                    <Headline>Milestones</Headline>
                </div>
                <div className="col-xs-12 col-sm-11 start-sm">
                    <div className={styles.milestones}>
                        <VerticalTimeline lineColor="#E9DAC7">
                            <VerticalTimelineElement
                                date="2022"
                                icon={<i className="icon-bloomberg" />}
                            >
                                <Headline level={3}>Bloomberg Quicktake</Headline>
                                <Headline level={4}>Video Interview</Headline>
                                <p><Obl to="https://www.bloomberg.com/news/videos/2022-11-21/ending-skin-tone-disparity-in-dermatology" title="Video Interview">Ending Skin Tone Disparity In Dermatology</Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2022"
                                icon={<i className="icon-afro" />}
                            >
                                <Headline level={3}>The Afro Newspaper</Headline>
                                <Headline level={4}>Written Interview</Headline>
                                <p><Obl to="https://afro.com/local-software-engineer-creates-melalogic-to-help-black-people-protect-their-skin-from-harmful-conditions-like-melanoma/" title="Written Interview"> Local software engineer creates Melalogic to help Black people protect their skin from harmful conditions, like melanoma </Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2022"
                                icon={<i className="icon-mozilla" />}
                            >
                                <Headline level={3}>Mozilla IRL</Headline>
                                <Headline level={4}>Audio Interview</Headline>
                                <p><Obl to="https://irlpodcast.org/season6/episode5/" title="Audio Interview">The AI Medicine Cabinet</Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2022"
                                icon={<i className="icon-cae" />}
                            >
                                <Headline level={3}>Collaborate &amp; Elevate</Headline>
                                <Headline level={4}>Video Interview</Headline>
                                <p><Obl to="https://www.youtube.com/watch?v=FHC-HkVqC8I" title="Video Interview">Avery Smith is building Melalogic to be a trustworthy and reliable resource for Black Skin health</Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2022"
                                icon={<i className="icon-rtbc" />}
                            >
                                <Headline level={3}>Reasons To Be Cheerful</Headline>
                                <Headline level={4}>Written Interview</Headline>
                                <p><Obl to="https://reasonstobecheerful.world/can-we-build-less-biased-medical-bots/" title="Written Interview">Can We Build Less Biased Medical Bots?</Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2022"
                                icon={<i className="icon-npr" />}
                            >
                                <Headline level={3}>NPR On The Record</Headline>
                                <Headline level={4}>Audio Interview</Headline>
                                <p><Obl to="https://www.wypr.org/show/on-the-record/2022-02-10/melalogic-app-hopes-to-shine-light-on-black-skin-concerns" title="Audio Interview">Melalogic app hopes to shine light on Black skin concerns</Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2021"
                                icon={<i className="icon-audible" />}
                            >
                                <Headline level={3}>Audible, an Amazon Company</Headline>
                                <Headline level={4}>Audio Interview</Headline>
                                <p><Obl to="https://www.audible.com/pd/The-Bias-Diagnosis-Podcast/B08W4Y7HYC" title="Audio Podcast Series">Founder Avery Smith is featured in Episode 1 of The Bias Diagnosis, an Audible Original Series</Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2021"
                                icon={<i className="icon-aicodes" />}
                            >
                                <Headline level={3}>AI: Decodes The System</Headline>
                                <Headline level={4}>Audio Interview</Headline>
                                <p><Obl to="https://aidecodes.buzzsprout.com/1479787/8072524-ep-15-part-2-of-ep-12-decoding-how-machines-only-learning-white-faces-can-lead-to-bad-outcomes-for-other-races" title="Audio Podcast Series">Decoding how machines only learning white faces can lead to bad outcomes for other races</Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2021"
                                icon={<i className="icon-technically" />}
                            >
                                <Headline level={3}>Technical.ly</Headline>
                                <Headline level={4}>Written Interview</Headline>
                                <p><Obl to="https://technical.ly/baltimore/2021/01/20/melalogic-avery-smith/" title="Technical.ly Baltimore Article">Baltimore startup Melalogic wins grant from Mozilla fund exploring the effects of AI on racial justice</Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2021"
                                icon={<i className="icon-mozilla" />}
                            >
                                <Headline level={3}>Mozilla Foundation</Headline>
                                <Headline level={4}>Award</Headline>
                                <p><Obl to="https://foundation.mozilla.org/en/blog/announcing-8-projects-examining-ais-relationship-with-racial-justice/" title="Mozilla Announcement">Melalogic Receives Mozilla Creative Media Award for Black Interrogations of AI</Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2020"
                                icon={<i className="icon-bmj" />}
                            >
                                <Headline level={3}>British Medical Journal</Headline>
                                <Headline level={4}>Written Interview</Headline>
                                <p><Obl to="https://www.bmj.com/content/368/bmj.m363" title="BMJ Article">Can we trust AI not to further embed racial bias and prejudice?</Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2019"
                                icon={<i className="icon-nsf" />}
                            >
                                <Headline level={3}>National Science Foundation</Headline>
                                <Headline level={4}>Achievement</Headline>
                                <p>Melalogic Completes National Science Foundation Regional I-Corps Program at the City University of New York</p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2018"
                                icon={<i className="icon-technically" />}
                            >
                                <Headline level={3}>Technical.ly</Headline>
                                <Headline level={4}>Written Interview</Headline>
                                <p><Obl to="https://technical.ly/baltimore/2018/09/04/baltimore-software-developer-calls-for-more-inclusive-data-in-machine-learning-that-helps-treat-skin-diseases/" title="Technical.ly Baltimore Article">Baltimore software developer calls for more inclusive data in machine learning that helps treat skin diseases</Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2018"
                                icon={<i className="icon-theatlantic" />}
                            >
                                <Headline level={3}>The Atlantic</Headline>
                                <Headline level={4}>Written Interview</Headline>
                                <p><Obl to="https://www.theatlantic.com/health/archive/2018/08/machine-learning-dermatology-skin-color/567619/" title="The Atlantic Article">AI-Driven Dermatology Could Leave Dark-Skinned Patients Behind</Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2018"
                                icon={<i className="icon-ama" />}
                            >
                                <Headline level={3}>American Medical Association</Headline>
                                <Headline level={4}>Research Paper Published</Headline>
                                <p><Obl to="https://jamanetwork.com/journals/jamadermatology/article-abstract/2688587" title="JAMA Article Abstract">Machine Learning and Health Care Disparities in Dermatology</Obl></p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date="2011"
                                icon={<i className="icon-latoyalives" />}
                            >
                                <Headline level={3}>Dr. LaToya Smith</Headline>
                                <Headline level={4}>February 20th 1982 - December 9th 2011</Headline>
                                <p>Went home to be with The Lord</p>
                            </VerticalTimelineElement>
                        </VerticalTimeline>
                    </div>
                </div>
            </div>
        </Layout>
    </article>
</div>)

export default CompanyPage
