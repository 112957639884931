// extracted by mini-css-extract-plugin
export var app = "index-module--app--7447d";
export var chart = "index-module--chart--f19a1";
export var company = "index-module--company--35a23";
export var features = "index-module--features--1cafb";
export var milestones = "index-module--milestones--36324";
export var number = "index-module--number--53c43";
export var phone = "index-module--phone--8a823";
export var text = "index-module--text--81715";
export var topRow = "index-module--topRow--a709e";
export var video = "index-module--video--5d4ff";
export var visualAid = "index-module--visualAid--1ea0f";